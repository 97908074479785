import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { contact } from '../../portfolio'
import './Contact.css'

const Contact = () => (
    <section className='section contact center' id='contact'>
          <h2 className='section__title'>Contact</h2>
          <a
              href={contact.linkedin}
              aria-label='linkedin'
              className='link link--icon'
          >
              <LinkedInIcon />
          </a>
    </section>
  )

export default Contact
