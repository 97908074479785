const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'http://isabelpijnenburg.com/',
  title: 'IP.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Isabel Pijnenburg',
  role: 'Game Developer',
  description:
    'I’m a 21 year old student from Tilburg and currently studying ICT at Fontys in Eindhoven. Besides game- design and development, I also like singing, writing music, playing guitar, and (of course!) playing games. My favorite games at the moment are Dead by Daylight and Devour.',
  // resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/isabel-pijnenburg/',
    github: 'https://github.com/isabel1410',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Kingdom’s Saviour',
    fotootje: '/static/media/kingdomssaviour.png',
    description:
      'The player is a Knight that needs to save the city of creatures that try to take over a Kingdom. Armed with a bow and a sword, they go around the city. Kingdom’s Saviour is a rail-shooter, meaning that the player’s movement is automatic. However, the player can choose to pick a route at certain points. The Knight’s objective is to clear the whole city to save the Kingdom.',
    stack: ['Unity3D', 'C#'],
    sourceCode: 'https://github.com/isabel1410/kingdoms-saviour',
    livePreview: 'https://fontys-gdt.itch.io/kingdoms-saviour',
  },
  {
    name: 'Kind Regards,',
    fotootje: '/static/media/kindregards.png',
    description:
      'A gamification solution to children waiting for a long time on a waiting list while waiting for youth care.',
    stack: ['Unity3D', 'C#', 'Blender'],
    sourceCode: 'https://github.com/isabel1410/kind-regards',
    livePreview: 'https://isabel-pijnenburg.itch.io/kind-regards',
  },
  {
    name: 'Rezone',
    fotootje: '/static/media/rezone.png',
    description:
      'A game in which up to four players have to strategically place their pawns in order to save as many zones as they can.',
    stack: ['Unity3D', 'C#'],
    livePreview: 'https://www.youtube.com/watch?v=QQa5J4-SGfE'
  },
  {
    name: 'Road of Anarchy',
    fotootje: '/static/media/roadofanarchy.png',
    description:
      'An online asymmetrical racing-shooter game in which players can take up the role of a Gunner or a Driver. These two players form a team. The objective of all players is to get to the finish line first. To finish as soon as possible, Gunners need to protect their team’s vehicle while the Drivers drive the vehicle.',
    stack: ['Unity3D', 'C#'],
    sourceCode: 'https://github.com/Patrick-van-Halm/unity-road_of_anarchy',
    livePreview: 'https://www.youtube.com/watch?v=jsjO2sxFhYQ'
    },
    {
        name: 'Skater',
        fotootje: '/static/media/skater.png',
        description:
            'Inspired by Ketchapp’s Rider, Skater is a game where the player aims to finish with as many points as possible. The game is a solo project to participate in a student game jam.',
        stack: ['Unity3D', 'C#'],
        sourceCode: 'https://github.com/isabel1410/skater',
        livePreview: 'https://isabel-pijnenburg.itch.io/skater'
    },
]

const schoolportfolio = {
    source: 'https://portfolio.drieam.app/s/GuxUr36X/jLhL94qyL5h1GbH1jzFgHuHe',
}

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Unity',
  'C#',
  'Git',
  'Java',
  'Blender',
  'React',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
    email: '',
    linkedin: 'https://www.linkedin.com/in/isabel-pijnenburg/',
}

export { header, about, projects, schoolportfolio, skills, contact }
