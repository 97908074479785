import { schoolportfolio } from '../../portfolio'
import './SchoolPortfolio.css'

const SchoolPortfolio = () => (
    <section className='section schoolportfolio center' id='schoolportfolio'>
        <h2 className='section__title'>Portfolio</h2>
        <p className='schoolportfolio__desc'> My school portfolio so far. Here&lsquo;s can see what I&apos;ve been working on so far during the study.</p>
        <iframe src={schoolportfolio.source} height='750' width='1000' title='School portfolio' />
    </section>
)

export default SchoolPortfolio